@import "src/assets/styles/helpers/variables";

.card {
  max-width: 424px;
  width: 100%;
  min-height: 367px;
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  padding: 20px;

  > h3 {
    font-size: $default;
    line-height: $default-height;
    margin-bottom: 20px;
  }

  > p {
    color: var(--dark);
  }
}