@import "src/assets/styles/helpers/mixin";

.footer {
  background: var(--black);
  color: var(--gray-light);
  padding: 60px 0;
  width: 100%;
}

.footerContainer {
  display: flex;
  flex-direction: column;
}

.footerNav {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--dark);

  ul {
    display: flex;
    @include _744 {
      flex-direction: column;
    }

    li {
      margin-right: 24px;
      @include _744 {
        margin-bottom: 12px;
      }
    }

    li:last-child {
      margin: 0;
    }
  }

  ul > li > a {
    text-decoration: none;
    color: var(--gray-light);
  }
}

.phoneNumber {
  color: var(--accent);
  font-weight: 600;
  text-decoration: none;
}

.footerInfo {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  text-align: end;
  color: var(--dark);
  @include _480 {
    flex-direction: column;
    text-align: start;
    gap: 24px;
  }

  p {
    @include _744 {
      width: 300px;
    }
  }
}