@import "src/assets/styles/helpers/mixin";
.button{
  cursor: pointer;
  width:100%;
  margin-top:48px;
  padding:12px 24px;
  border-radius: 8px;
  color:var(--white);
  border:none;
  outline:none;
  background:var(--accent);
  transition:0.2s;
  @include hoverButtonMixin

}
.button:hover{
  background: rgba(83, 179, 116, 0.6);
}