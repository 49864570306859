@import "src/assets/styles/helpers/variables";
.privacyPolicyAgreement{
  margin-top:24px;

  display:flex;
  justify-content: center;
  text-align: center;

  color:var(--gray);
  line-height:$text-small-height;
  font-weight:500;
  font-size:$text-small;
}
.privacyPolicyLink{
  color:var(--accent);
}