:root {
  --accent: #53B374;
  --accent-light: #EEF8F2;
  --accent-transparent: #C1E3CD;
  --white: #FFFFFF;
  --dark: #5D6963;
  --gray: #B4B4B9;
  --gray-light: #F2F2F2;
  --gray-transparent: rgba(30, 27, 24, 0.61);
  --dark-green: #5D6963;
  --black: #1E1E1E;
  --error: #EC6F68;
  --error-light: #FFE9E8;
  --body-font-family: 'Manrope', sans-serif;
  --body-font-size: 1rem;
  --body-line-height: 1.7rem;
  --body-font-weight: 400;
}