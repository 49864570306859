.numberCard {
  max-width: 424px;
  width: 100%;
  min-height: 141px;
  padding: 20px;
  background: var(--gray-light);
  border-radius: 8px;
}

.number {
  color: var(--dark);
  margin-bottom: 20px;
}

.text {
  color: var(--dark);
}

.helpText {
  color: var(--gray);
  margin-top: 10px;
}