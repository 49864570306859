.requisites {
  position: relative;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid var(--gray-light);

  &:first-child {
    border-top: 1px solid var(--gray-light);
  }
}

.title {
  max-width: 424px;
  width: 100%;
  color: var(--dark);
}

.description {
  max-width: 424px;
  width: 100%;
  font-weight: 600;
  user-select: all;
}