@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";
.heading{
  font-size: $title;
  line-height: $title-height;
}
.container{
  max-width:752px;
  margin-top:32px;
  display:flex;
  justify-content: space-between;
  div{
    @include _650{
      width: 45%;
    }
    @include _480{
      width: 100%;
    }
  }
  @include _480{
    flex-direction: column;
  }
  p{
    margin-top:10px;
    line-height:$sub-title-height;
    span{
      margin-right:10px;
      color:var(--gray);
    }
  }
}