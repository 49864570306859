@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.container {
  h1 {
    font-size: $title;
    font-weight: 600;
    line-height: $title-height;
  }

  p {
    margin-top: 32px;
  }
}

.map {
  border-radius: 8px;
  width: 100%;
  margin-top: 32px;
  height: 400px;
  background: url("Map.png") no-repeat ;
  background-size: cover;
  @include _480 {
    height: 300px;
  }
}