@import "src/assets/styles/helpers/mixin";

.accentButton {
  display: inline-block;
  padding: 14px 24px;
  border-radius: 8px;
  background: var(--accent);
  color: var(--white);
  text-decoration: none;

  @include hoverButtonMixin;
}