.infoItem {
  margin-bottom: 28px;

  > p {
    margin-bottom: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}