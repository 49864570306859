@import "src/assets/styles/helpers/variables";
::-webkit-input-placeholder {color:#B4B4B9;}
::-moz-placeholder          {color:#B4B4B9;}
:-moz-placeholder           {color:#B4B4B9;}
:-ms-input-placeholder      {color:#B4B4B9;}
.container {
  display:flex;
  flex-direction: column;
  gap:8px;
  margin-top:24px;
}
.inputContainer{
  position:relative;
}
.input {
  font-size: $default;
  line-height: $default-height;
  padding: 15.5px 24px;
  outline: none;
  margin-top:8px;
  border: 1px solid var(--gray);
  border-radius: 8px;
  width: 100%;

}

.icon {
  position: absolute;
  top: 57%;
  right: 26px;
  color: var(--gray);
  height:100%;
}

.description {
  font-size: 0.85rem;
  color: var(--gray);
}

.required {
  color: var(--error);
}

.fileInput {
  display: none;

}

.fileButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 37px 0;
  border: 1px dashed var(--gray);
  border-radius: 8px;

  p {
    margin-left: 12px;
    color: var(--accent);
    line-height: 170%;
    position: relative;
  }
  p:before{
    content:'';
    position: absolute;
    width:100%;
    height:1px;
    background: var(--accent-light);
    bottom:0;
  }

  svg {
    color: gray;
    height:auto;
    width:18px;
  }
}
.checkBoxContainer {
 margin-top:12px;
}
.checkBox {
  position: absolute;
  z-index: -1;

}

.checkBox + label {
  margin-top:-0px;
  display: flex;
  align-items: center;
  color: var(--dark);
}

.checkBox + label::before {
  content: '';
  width: 20px;
  height: 20px;
  border: 1px solid var(--gray);
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50% 50%;
}

.checkBox:checked:hover + label::before{
  box-shadow: 0 0 0 4px  var(--accent-light);
}
.checkBox:hover + label::before{
  border: 1px solid var(--dark);
}
.checkBox:checked + label::before {
  border-color: var(--accent);
  background-color: var(--accent);
  background-image: url("../../../../../public/images/icons/check.svg");
}
.textarea{
  overflow: hidden;
  min-height:102px;
  font-size: 1rem;
  line-height: 27px;
  padding: 12px 24px;
  outline: none;
  border: 1px solid var(--gray);
  border-radius: 8px;
  resize: none;
}