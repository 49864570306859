@import "src/assets/styles/helpers/mixin";

.row {
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 20px;

  @include _1024 {
    column-gap: 2%;
  }

  @include _744 {
    row-gap: 20px;
  }
}

.ad {
  margin-top: 24px;
}