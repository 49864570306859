@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.searchBox {
  width: 100%;
  min-height: 360px;
  background: url("../../../../../public/images/bg-images/search-box.png");
  border-radius: 8px;
  position: relative;
  padding-top: 96px;
}

.searchBoxContent {
  position: relative;
  z-index: 10;
  max-width: 1060px;
  margin: 0 auto;

  > h1 {
    color: var(--white);
    font-size: $header;
    line-height: $header-height;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;

    @include _480 {
      font-size: $header-mob;
      line-height: $header-mob-height;
    }
  }
}

.row {
  display: flex;
  gap: 12px;

  @include _1250 {
    display: none;
  }
}

.searchParameters {
  background: var(--white);
  width: 100%;
  max-width: 818px;
  padding: 6px 20px;
  border-radius: 8px;
  display: flex;
  gap: 50px;
}

.searchParameter {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 32px;
    height: 1px;
    background: var(--gray);
    top: 50%;
    right: -35px;
    transform: rotate(90deg) translateY(-50%);
  }
}

.searchBtn {
  color: var(--white);
  background: var(--accent);
  border-radius: 8px;
  padding: 12px 32px;
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  @include hoverButtonMixin;
}

.searchBoxMask {
  top: 0;
  left: 0;
  inset: 0;
  position: absolute;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background: rgba(30, 30, 30, 0.6);
  z-index: 5;
}

.wrap {
  border-radius: 5px;
  display: inline-block;
}