.item {
  border-bottom: 1px solid var(--gray-light);

  &:first-child {
    border-top: 1px solid var(--gray-light);
  }
}

.button {
  padding: 24px 48px 24px 0;
  font-weight: 500;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    background: url("../../../public/images/icons/arrow_accordion.svg");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.4s;
  }

  &[aria-expanded=true]::before {
    transform: translateY(-50%) rotate(180deg);
  }
}

.content {
  padding-bottom: 24px;
  padding-right: 50px;
}