@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.container {
  position: sticky;
  top: 100px;
  @include _480 {
    position: static;
  }
  h1 {
    font-size: $header;
    font-weight: 600;
    line-height: $header-height;
    @include _480 {
      margin-top: 4px;
      font-size: $title-mob;
      line-height: $title-mob-height;
    }
  }

  p {
    margin-top: 4px;
    line-height: $sub-title-height;

  }

  > p {
    @include _480 {
      color: var(--gray)
    }
  }

  span {
    color: var(--accent);
  }
}

.button {
  margin-top: 20px;
  @include _480{
    margin-top:60px;
  }
}

.contacts {
  margin-top: 20px;
  line-height: $text-small-height;

  span {
    color: var(--gray);
  }
}
.address{
  display: none;
  @include _480{
    margin-top:32px;
    display: block;
  }
}