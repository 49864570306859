@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.row {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.offerCard {
  max-width: 424px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--gray-light);
  padding: 20px;

  > h3 {
    font-size:  $default;
    line-height: $default-height;
    font-weight: 600;
    margin-bottom: 20px;
  }

  > ul {
    > li {
      margin-bottom: 10px;
      > a {
        text-decoration: none;
        color: var(--dark);
      }
    }
  }

  @include _1024 {
    max-width: 100%;
  }
}