@import "src/assets/styles/helpers/variables";
.container{
  h1{
    font-size: $title;
    line-height:$title-height;
    font-weight:600;
  }
  p{
    margin-top: 32px;
    line-height: $sub-title-height;
  }
}