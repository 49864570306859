@import "src/assets/styles/helpers/variables";

.card {
  max-width: 312px;
  width: 100%;
  min-height: 424px;
  position: relative;
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  inset: 0;
}

.imgWrapper {
  max-width: 312px;
  max-height: 209px;

  img {
    max-width: 100%;
    height: auto;
  }
}

.body {
  padding-top: 24px;
}

.date {
  color: var(--gray);
  margin-bottom: 8px;
}

.title {
  font-size: $default;
  line-height: $default-height;
  font-weight: 600;
}

.pos {
  top: 30%;
  transform: translateY(-30%);
}