@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.heading {
 > h1 {
    font-size: $header;
    line-height: $header-height;
    font-weight: 600;
    margin-bottom: 0;

    @include _650 {
      font-size: calc($header - 0.35rem);
    }

    @include _480 {
      margin-top:32px;
      font-size: $title-mob;
      line-height: $title-mob-height;
    }
  }




}
.priceMobile{
  display: none;
  @include _480{
    display: block;
  }
}
.address{
  p {
    margin-top: 12px;
    line-height: $sub-title-height;
    @include _480{
      margin-top:4px;
    }
  }

  p:last-child {
    margin-top: 6px;
    @include _480{
      margin-top:4px;
    }
  }
  span {
    color: var(--accent);
  }
  @include _480{
    display:none;
  }
}
.verified{
  margin-top:12px;
  max-width: 178px;
  padding:2px 8px;
  font-size: $text-small;
  line-height: $text-small-height;
  color:var(--accent);
  background: var(--accent-light);
  border-radius: 8px;
  @include _480{
    display: none;
  }
}