@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.categoryCard {
  max-width: 426px;
  width: 100%;
  min-height: 172px;
  position: relative;
  border: 1px solid var(--gray);
  border-radius: 8px;
  padding: 20px;

  > h2 {
    font-size:  $default;
    line-height: $default-height;
    font-weight: 600;
    margin-bottom: 10px;
  }

  @include _1024 {
    max-width: 49%;
  }

  @include _744 {
    max-width: 100%;
  }
}

.icon {

}

.link {
  position: absolute;
  top: 0;
  left: 0;
  inset: 0;
}