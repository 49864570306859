.photoRoom {
  width: 312px;
  height: 312px;

  img {
    max-width: 100%;
    height: auto;
  }
}

.pos {
  top: 50%;
  transform: translateY(-50%);
}