@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.titleBox {
  max-width: 400px;

  > h3 {
    font-size: $title;
    line-height: $title-height;
    font-weight: 600;

    @include _744 {
      margin-bottom: 48px;
    }

    @include _480 {
      font-size: $title-mob;
      line-height: $title-mob-height;
    }
  }

  @include _1024 {
    max-width: 288px;
  }

  @include _744 {
    max-width: 100%;
  }
}