@import "src/assets/styles/helpers/mixin";
.editorWrapper {
  margin-top:24px;
    .toolbar {
      margin: 8px 0 0 0;
      padding:20px 0;
      border:1px solid var(--gray);
      border-radius: 8px 8px 0 0;
      background: var(--gray-light);
      >div:first-child{
        margin-right:20px;
      }
      > div {
        margin-right: 10%;
        @include _360{
          margin-right: 5%;
        }
        > div {
          margin:0;
          background: transparent;
        }
        >div:hover{
          box-shadow: 0 0 2px var(--gray);
        }
      }
    }

    .editor {
      min-height:220px;
      border:1px solid var(--gray);
      border-top:none;
      padding:14px 24px;
      border-radius: 0 0 8px 8px;
    }
  }

