@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";
.container{
  position: relative;
  max-width: 100%;
}
.swiper {
  height: 560px;
  margin-top: 30px;
  display: flex;
  position: relative;
  @include _744{
    height: 450px;
  }
  @include _650{
    height: 400px;
  }
  @include _480{
    height: 300px;
    margin-top: 0;
  }
  @include _360{
    height: 218px;
  }
  div {
    display: flex;
  }
}
.slide{
  height: 560px;
  position:relative;
  background-size:100%;
  @include _744{
    height: 450px;
  }
  @include _650{
    height: 400px;
  }
  @include _480{
    height:300px;
  }
  @include _360{
    height: 218px;
  }
  img{
    height:100%;
    margin: auto;
    z-index:5;
  }
}
.slide:after{
  content:'';
  width:1000px;
  height:560px;
  position: absolute;
  background: rgba(128, 128, 128, 0.37);
  backdrop-filter: blur(55px);
}
.navigation {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
 @include _480{
   display: none;
 }
  div {
    width: 86px;
    height: 64px;

    img {
      border-radius: 5px;
      width: 86px;
      height: 64px;
    }
  }
}

.active {
  border-radius: 5px;
  outline: 2px solid var(--accent);
}
.prevButton{
  display:flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  top:36%;
  left:20px;
  position: absolute;
  width:52px;
  height:52px;
  border-radius: 50%;
  background: rgba(30, 30, 30, 0.75);
  border:none;
  outline:none;
  cursor: pointer;

  @include _480{
    top:45%;
    width:40px;
    height:40px;
    left:10px;
  }
}
.nextButton{
  display:flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  top:36%;
  right:20px;
  position: absolute;
  width:52px;
  height:52px;
  border-radius: 50%;
  background: rgba(30, 30, 30, 0.75);
  border:none;
  outline:none;
  cursor: pointer;
  svg{
    transform: rotateZ(180deg);
  }
  @include _480{
    top:45%;
    right:10px;
    width:40px;
    height:40px;
  }
}
.slideCounter{
  display: none;
  @include _480{
    display: block;
    position: absolute;
    bottom:10px;
    left:45%;
    z-index: 6;
    padding: 2px 8px;
    font-size: $text-small ;
    background: rgba(30, 30, 30, 0.8);
    border-radius: 8px;
    color:white;
  }
}
