@import "src/assets/styles/helpers/mixin";
.container{
  display:flex;
  justify-content: space-between;
  gap:60px;
  @include _1250{
    gap:20px;
  }
  @include _480{
    padding: 0 ;
  }
}
.ASide{
  display:flex;
  flex-direction: column;
  gap:60px;
  width: 872px;
  @include _1250{
    width: 800px;
  }
  @include _1150{
    width: 695px;
  }
  @include _1100{
    width: 685px;
  }
  @include _1024{
    width: 672px;
  }
  @include _1015{
    width: 100%;
  }
}
.BSide{
  display:flex;
  flex-direction: column;
  flex:1;
  position: relative;
  @include _1024{
    width: 292px;
  }
  @include _1015{
    display: none;
  }
}
.headingContainer{
  @include _480{
    display: flex;
    flex-direction: column-reverse;
  }
}
