@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.wrapper {
  display: flex;
  justify-content: space-between;
}

.aSide {
  max-width: 872px;

  @include _1250 {
    max-width: 672px;
  }

  @include _900 {
    max-width: 100%;
  }
}

.bSide {
  max-width: 408px;

  @include _1250 {
    max-width: 292px;
  }

  @include _900 {
    display: none;
  }
}

.title {
  font-size: $header;
  line-height: $header-height;
  font-weight: 600;
  margin-bottom: 20px;

  @include _480 {
    font-size: $header-mob;
    line-height: $header-mob-height;
  }
}

.linkBack {
  color: var(--accent);
  font-weight: 600;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}

.date {
  color: var(--gray);
  margin-bottom: 48px;
}

.text {
  margin-bottom: 48px;
}

.photosTitle {
  font-size: $default;
  line-height: $default-height;
  font-weight: 400;
  color: var(--gray);
  margin-bottom: 8px;
}

.photoItem {
  width: 312px;
  height: 312px;

  img {
    max-width: 100%;
    height: auto;
  }
}

.pos {
  top: 50%;
  transform: translateY(-50%);
}

.contactsBox {
  background: var(--accent-light);
  padding: 24px;
  border-radius: 8px;
  position: sticky;
  top: 100px;

  > h3 {
    font-weight: 600;
    margin-bottom: 12px;
  }

  > p {
    margin-bottom: 24px;
  }
}

.contactsBtn {
  width: 100%;
  display: inline-block;
  border-radius: 5px;
  background: var(--accent);
  color: var(--white);
  text-decoration: none;
  text-align: center;
  padding: 8px 0;

  @include hoverButtonMixin;
}