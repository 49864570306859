.pagination {
  display: flex;
  gap: 8px;
}

.paginationItem {
  cursor: pointer;

  &.active > .pageLink{
    background: var(--accent);
    color: var(--white)
  }
}

.paginationPrev {
  order: -1;
}

.paginationNext {
  order: 1;
}

.pageLink {
  text-decoration: none;
  color: var(--dark);
  border: 1px solid var(--gray-light);
  border-radius: 4px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: 0.2s all;

  &:active {
    background: var(--accent-light);
    color: var(--accent);
  }
}
