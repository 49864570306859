@import "src/assets/styles/helpers/variables";
.headingContainer {
	margin-bottom: 50px;
}
.heading {
	font-size: $header;
	font-weight: 600;
	line-height: $header-height;
}
.subHeading {
	margin-top: 12px;
	display: flex;
	p {
		color: var(--dark);
	}
	a {
		text-decoration: none;
		padding-left: 5px;
		color: var(--accent);
	}
}
