.servicesCard {
  width: 312px;
  height: 132px;
  border-radius: 8px;
  border: 1px solid var(--gray);
  padding: 20px;
  position: relative;

  > a {
    position: absolute;
    top: 0;
    left: 0;
    inset: 0;
    z-index: 5;
  }

  > p {
    max-width: 220px;
  }
}

.icon {
  color: var(--accent);
  font-size: 48px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.servicesWrapper {
  position: relative;
}

.pos {
  top: 50%;
  transform: translateY(-50%);
}