@import "src/assets/styles/helpers/mixin";


.next {
  width: 38px;
  height: 38px;
  background: var(--white);
  border: none;
  box-shadow: 0 4px 4px rgba(93, 105, 99, 0.3);
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 20;
  color: var(--gray);
  right: -20px;
  transition: 0.4s;

  &:disabled {
    opacity: 0;
  }

  &:hover {
    background: var(--accent);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-user-select: none;
    -webkit-touch-callout: none; /*only to disable context menu on long press*/

    svg, polygon, path {
      stroke: #fff;
    }
  }

  @include _900 {
    &:hover {
      background: var(--white);

      svg, polygon, path {
        stroke: var(--gray);
      }
    }
  }
}