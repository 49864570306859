@import "../helpers/mixin";

$breakpoints: (
        sm: 480px,
        md: 744px,
        lg: 1024px
);

$intervals: (
        0: 0,
        1: 0.25,
        2: 0.5,
        3: 0.75,
        4: 1,
        5: 1.5,
        6: 3
);

@each $interval-name, $interval in $intervals {
  .mr-#{$interval-name} {
    margin-right: #{$interval}rem !important;
  }
  .ml-#{$interval-name} {
    margin-left: #{$interval}rem !important;
  }
  .mt-#{$interval-name} {
    margin-top: #{$interval}rem !important;
  }
  .mb-#{$interval-name} {
    margin-bottom: #{$interval}rem !important;
  }

  @each $breakpoint-name, $breakpoint in $breakpoints {
    @media(max-width: $breakpoint) {
      .mb-#{$breakpoint-name}\-#{$interval-name} {
        margin-bottom: #{$interval}rem !important;
      }
      .mt-#{$breakpoint-name}\-#{$interval-name} {
        margin-top: #{$interval}rem !important;
      }
      .mr-#{$breakpoint-name}\-#{$interval-name} {
        margin-right: #{$interval}rem !important;
      }
      .ml-#{$breakpoint-name}\-#{$interval-name} {
        margin-left: #{$interval}rem !important;
      }
    }
  }
}

$colors: (
        gray: var(--gray),
        grayLight: var(--gray-light),
        dark: var(--dark),
        accent: var(--accent)
);

@each $color-name ,$color in $colors {
  .color-#{$color-name} {
    color: $color !important;
    font-weight: 400 !important;
  }
}


.lock {
  overflow: hidden;
}