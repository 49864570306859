@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.wrapper {
  display: flex;
  justify-content: space-between;

  > h1 {
    margin-bottom: 48px;
  }

  &.indent {
    margin-bottom: 48px;
  }

  @include _744 {
    display: block;
  }
}

.header {
  max-width: 338px;
  width: 100%;

  > h1 {
    font-size: $title;
    line-height: $title-height;

    @include _480 {
      font-size: $title-mob;
      line-height: $title-mob-height;
    }
  }

  > h2 {
    font-size: $default;
    line-height: $default-height;
    font-weight: 400;
    color: var(--gray);
    margin-bottom: 8px;
  }

  > p {
    margin-bottom: 24px;
  }

  @include _1024 {
    max-width: 288px;
  }

  @include _744 {
    max-width: 100%;
  }
}

.content {
  max-width: 872px;
  width: 100%;

  p {
    span {
      font-weight: 600;
    }

    a {
      text-decoration: none;
      color: var(--accent);
    }
  }

  h2 {
    font-size: $default;
    line-height: $default-height;
    font-weight: 400;
    color: var(--gray);
  }

  @include _1024 {
    max-width: 648px;
  }

  @include _744 {
    max-width: 100%;
  }
}

