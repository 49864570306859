@import "src/assets/styles/helpers/mixin";

.content {
  max-width: 872px;
  width: 100%;
  padding-top: 5px;

  > p {
    margin-bottom: 24px;
  }

  @include _1024 {
    max-width: 648px;
  }

  @include _744 {
    max-width: 100%;
  }
}