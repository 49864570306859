
.container {
	display: flex;
	flex-direction: column;
	margin-top:24px;

	p {
		margin-bottom: 8px;
	}
}

.switches {
	border-radius: 12px;
	.switchButton:first-child {
		border-right: none;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	.switchButton:last-child {
		border-right:1px solid var(--gray);
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}
}
.switchButton {
	cursor: pointer;
	background: transparent;
	padding: 14px 24px;
	border: 1px solid var(--gray);
	border-right:none;
}
.active {
	background: var(--accent);
	animation: 1 0.15s switchAnimation;
	color: var(--white);
}

@keyframes switchAnimation {
	from {
		background: transparent;
	}
	to {
		background: var(--accent);
	}
}
