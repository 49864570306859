@import "src/assets/styles/helpers/mixin";

.mainWrapper {
  background: var(--white);
  //height: 100vh;
  //overflow-y: scroll; /* для создания скролла внутри .content */

  @include _1150 {
    padding: 0;
  }
}