@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.flatCard {
  position: relative;
  max-width: 427px;
  width: 100%;
  min-height: 462px;

  @include _480 {
    max-width: 320px;
    min-height: 100%;
  }
}

.flatLink {
  position: absolute;
  top: 0;
  left: 0;
  inset: 0;
}

.imgWrapper {
  max-width: 427px;
  max-height: 240px;
  border-radius: 8px;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
}

.body {
  padding-top: 20px;
}

.time {
  color: var(--gray);
  font-size: $text-small;
  line-height: $text-small-height;
}

.about {
  margin-top: 22px;
}

.status {
  background: var(--accent-light);
  padding: 2px 8px;
  color: var(--accent);
  font-size: $text-small;
  line-height: $text-small-height;
  border-radius: 5px;
}

.info {
  font-size: $title;
  line-height: $title-height;
  font-weight: 600;
  display: flex;
  gap: 8px;
  margin-top: 10px;

  @include _480 {
    font-size: $title-mob;
    line-height: $title-mob-height;
  }
}

.options {
  color: var(--dark);
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.address {
  color: var(--gray);
  margin-top: 8px;
}

.price {
  font-weight: 600;
  color: var(--accent);
}