@import "src/assets/styles/helpers/mixin";

.seeAllLink {
  text-decoration: none;
  color: var(--accent);
  position: relative;
  display: inline-block;

  &::before {
    content: '';
    width: 95%;
    height: 2px;
    background: rgba(83, 179, 116, 0.4);
    position: absolute;
    opacity: 0.4;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);

    @include _480 {
      display: none;
    }
  }

  @include _480 {
    text-align: center;
    color: var(--white);
    background: var(--accent);
    width: 100%;
    padding: 12px 0;
    border-radius: 8px;
    margin-top: 48px;
  }

  @include _360 {
    margin-top: 20px;
  }

  &.mobileNone {
    @include _480 {
      display: none;
    }
  }

  &.desktopNone {
    display: none;

    @include _480 {
      display: inline-block;
    }
  }
}