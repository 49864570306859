@import "src/assets/styles/helpers/variables";
.questionsContainer{
  max-width: 944px;
  >h1{
    font-weight: 600;
    font-size: $header;
    line-height: $header-height;
  }
}

.container{
  border:1px solid var(--gray-light);
  border-radius:8px;
  padding:20px;
  margin-top:20px;
}