@import "src/assets/styles/helpers/mixin";

.navbar {
  background: var(--white);
  width: 100%;
  border-bottom: 1px solid var(--gray-light);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 20px 0;
  transition:  0.4s transform ease-in-out ;
  transform: translateY(-100%);

  &.visible {
    transform: translateY(0%);
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include _650 {
    display: block;
  }
}

.navList {
  display: flex;

  li {
    margin-right: 24px;

    &:last-child {
      margin: 0;
    }
  }

  > li > a {
    color: var(--black);
    text-decoration: none;
    font-weight: 600;
    //transition: 0.4s;
    position: relative;

    &.active {
      color: var(--accent);

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        border: 1px solid var(--accent);
        bottom: -33px;
      }
    }
  }

  @include _1150 {
    display: none;
  }
}

.content {
  display: flex;
  list-style: none;

  > li {
    margin-right: 12px;

    &:last-child {
      margin: 0;
    }
  }
}

.advtBtn {
  background: var(--accent);
  color: var(--white);
  text-decoration: none;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 5px;

  @include hoverButtonMixin;

  @include _650 {
    display: none;
  }
}

.loginBtn {
  border: 1px solid var(--accent);
  color: var(--accent);
  padding: 8px 12px;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    border-color: var(--accent-transparent);
    color: var(--accent-transparent);
  }

  @include _650 {
    display: none;
  }
}

.group {
  display: flex;
  gap: 24px;
  align-items: center;


  @include _650 {
    justify-content: space-between;
    gap: 0;
  }
}

.burger {
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  cursor: pointer;
  display: none;

  span {
    width: 30px;
    height: 2.5px;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--gray);
    transition: all 0.3s linear;

    &:nth-of-type(2) {
      top: calc(50% - 10px);
    }

    &:nth-of-type(3) {
      top: calc(50% + 10px);
    }
  }

  &.active {
    span {
      background-color: var(--dark);

      &:nth-of-type(1) {
        width: 0;
      }

      &:nth-of-type(2) {
        top: 50%;
        transform: translate(-50%, 0%) rotate(45deg);
      }

      &:nth-of-type(3) {
        top: 50%;
        transform: translate(-50%, 0%) rotate(-45deg);
      }
    }
  }

  @include _1150 {
    display: block;
  }
}
